<template>
  <base-material-card inline class="mb-0" icon="mdi-null" :title="$t('Câmpuri vide')">
    <v-row dense>
      <v-col cols="12" sm="4" md="3" lg="2">
        <v-navigation-drawer permanent>
          <v-list dense nav class="pl-1">
            <v-list-item-group v-model="prefix" mandatory color="primary">
              <template v-for="pre in prefixes">
                <v-list-item :key="pre.value" dense>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t(pre.text) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <!-- <v-divider v-if="index < reports.length - 1" :key="index"></v-divider> -->
              </template>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
      </v-col>
      <v-col cols="12" sm="8" md="9" lg="10">
        <v-row dense>
          <v-col cols="12">
            <v-autocomplete
              v-model="field"
              :label="$t('Câmp')"
              :items="fields"
              hide-details
              return-object
              clearable
              @change="fieldChanged"
            />
          </v-col>
          <v-col cols="12">
            <table-management
              ref="tableold"
              name="tmcorectorold"
              :table="collection"
              :where="where"
              view-type="marc"
              @fieldsChanged="fieldsChanged"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </base-material-card>
</template>
<script>
import TableManagement from "../../../components/containers/TableManagement"
export default {
  components: {
    TableManagement
  },
  data: () => ({
    field: null,
    collection: "marc_cln",
    fields: [],
    prefix: 0,
    where: "",
    prefixes: [
      { text: "Utilizatori", value: "cln" },
      { text: "Monografii", value: "mon" },
      { text: "Inventare", value: "inv" },
      { text: "Articole", value: "art" },
      { text: "Autorități", value: "ath" },
      { text: "Evenimente", value: "evn" },
      { text: "Furnizori", value: "prv" },
      { text: "RMF", value: "rmf" }
    ]
  }),
  computed: {
    appSchema() {
      return this.$store.getters.appSchema
    }
  },
  watch: {
    prefix() {
      this.$log("prefix ", this.prefixes[this.prefix].value)
      this.field = null
      this.where = ""
      this.collection = "marc_" + this.prefixes[this.prefix].value
    }
  },
  methods: {
    fieldsChanged(e) {
      this.fields = e
    },
    fieldChanged(e) {
      this.$log("fieldChanged ", e)
      if (e) {
        this.where = '"' + e.value + '":{"$exists":false}'
      } else {
        this.where = ""
      }
    }
  }
}
</script>
